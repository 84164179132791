<template>
  <div v-if="showPopup" id="popupBG" class="popupBG" @click="showPopup = false">
    <div class="popupContainer" :style="xs ? 'width:90%' : 'width:25%'">
        <div class="popUp">
            <v-icon class="icon-close">mdi-close-box</v-icon>
            <a href="https://disneyonice.uhuu.com/" target="_blank">
              <img :src="popupDisneyOnIce2025">
            </a>
        </div>		
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import popupDisneyOnIce2025 from '@/assets/popupDisneyOnIce2025.jpg';
import { useDisplay } from 'vuetify';
let showPopup = ref(true);
const { xs } = useDisplay();
</script>

<style scoped>
.popupBG {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 50%);
}
.popupContainer {
    /*width: 700px;*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.popUp {
    width: 100%;
    max-width: 700px;
    height: auto;
}
.popUp img {
    width: 100%;
    margin: 0 auto;
    display: block;
}
.popUp .icon-close {
    color:white;
    float:right;
}
</style>